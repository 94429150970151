//
// Secondary button
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .btn.btn-secondary {
    @include button-custom-variant(
      $color: null,
      $icon-color: var(--#{$prefix}gray-600),
      $border-color: null,
      $bg-color: var(--#{$prefix}gray-200),
      $color-active: null,
      $icon-color-active: var(--#{$prefix}primary),
      $border-color-active: null,
      $bg-color-active: var(--#{$prefix}gray-300)
    );
  }
}

@include color-mode(dark) {
  // Secondary button
  .btn.btn-secondary {
    @include button-custom-variant(
      $color: null,
      $icon-color: #878794,
      $border-color: null,
      $bg-color: #2b2b40,
      $color-active: null,
      $icon-color-active: null,
      $border-color-active: null,
      $bg-color-active: #1a1a1a
    );
  }
}
